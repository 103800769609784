<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      width="500"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          v-on="on"
        >
            <slot></slot>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h6 #e6205a">Promote / Demote Students</v-card-title>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content class="text-center">
                <!-- Let user decide section -->
                <v-row>
                    <v-col>
                        <v-btn tile text color="success" @click="promoteByClass"><v-icon>mdi-account-group </v-icon>&nbsp;Class By Class</v-btn>  
                    </v-col>
                    <v-col>
                        <v-btn tile text color="info" @click="promoteByStudent"><v-icon>mdi-account-arrow-right </v-icon>&nbsp;Student By Student</v-btn>  
                    </v-col>
                </v-row>

                <!-- Class by Class Promotion -->
                <v-form v-if="pbc" v-model="valid">
                    <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        md="6"
                        >
                        <v-select
                        :items="classes"
                        item-text="class"
                        item-value="class_id"
                        label="Move Students FROM:"
                        v-model="form.from_class_id"
                        outline
                        :rules="[v => !!v || 'Class need to be selected!']"
                        required
                        ></v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        md="6"
                        >
                        <v-select
                        :items="classes"
                        item-text="class"
                        item-value="class_id"
                        label="Move Students TO:"
                        v-model="form.to_class_id"
                        outline
                        :rules="[v => !!v || 'Class need to be selected!']"
                        required
                        ></v-select>
                        </v-col>
                        <v-col
                        cols="12"
                        md="12"
                        >
                        <v-btn dark @click="promoteStudents" :disabled="!valid">Move To New Class</v-btn>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-form>
                
                <!-- Student by Student Promotion -->
                <v-form v-if="pbs && school.plan !== 'Free'" v-model="valid">
                    <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        md="6"
                        >
                        <v-select
                        :items="classes"
                        item-text="class"
                        item-value="class_id"
                        label="Move Students FROM:"
                        v-model="form.from_class_id"
                        @change="fetchStudents(form.from_class_id)"
                        outline
                        :rules="[v => !!v || 'Class need to be selected!']"
                        required
                        ></v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        md="6"
                        >
                        <v-select
                        :items="classes"
                        item-text="class"
                        item-value="class_id"
                        label="Move Students TO:"
                        v-model="form.to_class_id"
                        outline
                        :rules="[v => !!v || 'Class need to be selected!']"
                        required
                        ></v-select>
                        </v-col>
                        <v-col
                        cols="12"
                        md="12"
                        >
                        <v-select
                        :items="students"
                        item-text="name"
                        item-value="student_id"
                        label="Select Student(s):"
                        v-model="form.student_ids"                        
                        chips
                        multiple
                        outline
                        :rules="[(v => !!v) && ((v) =>  v.length>0) || 'Students need to be selected!']"
                        required
                        ></v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        md="12"
                        >
                        <v-btn dark @click="promoteStudents" :disabled="!valid">Move Students</v-btn>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-form>

                <premium-info v-if="pbs && school.plan === 'Free'"/>
                
                <v-divider></v-divider>
                <v-card-subtitle class="caption">Do this ONLY after setting new School SESSION</v-card-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import PremiumInfo from './PremiumInfo.vue'
import axios from 'axios';
    export default {
        name: 'PromoteDemoteStudents',
        components: { PremiumInfo },
        props:[
            'classes','authToken','school'
        ],
        data () {
        return {
            dialog: false,
            form: {},
            students: [],
            pbc:false,
            pbs:false,
            valid:true,
        }
    },
    methods: {
        fetchStudents (classId){
            let loader = this.$loading.show()
            axios.get(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id+'&class_id='+classId,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.students =  response.data.data     
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                loader.hide()
            });
        },
        promoteStudents (){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/promotions?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.$emit('promoted', 'yes')           
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        promoteByClass (){
            this.pbc = true;
            this.pbs = false;
            this.form = {}
        },
        promoteByStudent (){
            this.pbs = true;
            this.pbc = false;
            this.form = {}
        }
    }
  }
</script>

<style scoped>

</style>